<template>
    <div class="base-player"
         :class="{'opened': isVisible}"
         :style="{ 'background-image': 'url(' + getPlayerBg + ')' }"
    >
        <div class="base-player__cover"></div>
        <div class="base-player__close-btn" v-if="!isPromoLanding" @click="hidePlayer"></div>
        <div class="base-player__add-to-cart-btn"
             v-if="isAddToCardButtonVisible"
             :class="{'disabled': isAddToCartButtonDisabled}"
             @click="addToCart(currentID, priceWithSale)"
        >
            Купить полную <br>
            версию за <strong>{{priceWithSale}} $</strong>
        </div>
        <template v-if="isPromoVideoEnabled">
            <div class="base-player__youTube promoPage">
                <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/9-m5bQQw07E?modestbranding=1&rel=0"
                        frameborder="0"
                        modestbranding='1'
                        allowfullscreen
                ></iframe>
            </div>
        </template>
        <template v-else>
            <div class="videoPLayer"  v-if="currentMeditation.type === 'webinar'">
                <div class="base-player__youTube" v-if="isYouTube">
                    <iframe
                            width="100%"
                            height="315"
                            :src="getYouTubeUrl()"
                            frameborder="0"
                            allowfullscreen
                    ></iframe>
                </div>
                <div id="player"
                     data-plyr-provider="youtube"
                     :data-plyr-embed-id="getVideoUrl()"
                     v-else
                ></div>
            </div>
            <div class="audioPlayer" v-else>
                <audio id="player" controls>
                    <source :src="getUrl" type="audio/mp3" />
                </audio>
            </div>
        </template>

        <div class="base-player__descr" v-if="playerDescr !== ''">
            <div v-html="playerDescr" v-if="!isPromoLanding"></div>
            <div v-else-if="isPromoVideoEnabled">
                Что происходит с теми, кто слушал медитации?
                Посмотрите видео и переходите дальше,
                чтобы послушать другие медитации.

                <router-link to="/meditations" class="es-button">Дальше</router-link>
            </div>
            <div v-else>
                Послушайте медитацию и переходите дальше
                <button class="es-button" @click="enableVideo()">Дальше</button>
            </div>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';
import NoSleep from 'nosleep.js';
import axios from 'axios';

export default {
  name: 'BasePlayer',
  components: {},
  props: {
    descr: String,
  },
  data() {
    return {
      player: '',
      isVisible: false,
      noSleep: '',
      isYouTube: false,
      isPromoVideoEnabled: false,
    };
  },
  computed: {
    mediationInfo() {
      return this.$store.state.meditations.currentMeditation;
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    getPlayerBg() {
      if (this.mediationInfo.playerImage) {
        return this.mediationInfo.playerImage;
      }

      return this.currentMeditationGroup.playerImage;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    isLandingPage() {
      return this.$store.state.user.landingUserType !== '';
    },
    isPromoLanding() {
      return this.$route.name === 'promo1';
    },
    playerDescr() {
      return this.$store.state.general.playerDescr;
    },
    getUrl() {
      const data = this.$store.state.meditations.currentMeditation;
      let returnLink = data.isPayed ? data.audio : data.audioPreview;

      if (this.isLandingPage) {
        returnLink = data.audioPreview;
      }

      return returnLink;
    },
    currentID() {
      return this.$store.state.meditations.currentMeditation.id;
    },
    isPayed() {
      return this.$store.state.meditations.currentMeditation.isPayed;
    },
    isAddToCardButtonVisible() {
      return !this.isPayed;
    },
    isAddToCartButtonDisabled() {
      return this.isInCart;
    },
    saleCoof() {
      return this.$store.state.general.saleCoof;
    },
    priceWithSale() {
      return this.$store.state.meditations.currentMeditation.discrPrice * this.saleCoof;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    isInCart() {
      return this.cartList.includes(this.currentID);
    },
  },
  methods: {
    addToCart(id, price) {
      if (this.isInCart) {
        return;
      }

      this.$store.commit('addToCart', id);

      this.$store.commit('setCurrentTemplate', 'cart-sale');
      this.$store.commit('showDialog');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddToCart', {
          content_ids: [id.toString()],
          content_type: 'product',
          currency: 'USD',
          value: price,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'AddToCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    enableVideo() {
      this.isPromoVideoEnabled = true;
    },
    hidePlayer() {
      this.$store.commit('hidePlayer');
    },
    getVideoUrl() {
      const data = this.$store.state.meditations.currentMeditation;

      return data.isPayed ? data.audio : data.audioPreview;
    },
    getYouTubeUrl() {
      const data = this.$store.state.meditations.currentMeditation;
      const url = data.isPayed ? data.audio : data.audioPreview;

      return `https://www.youtube.com/embed/${url}`;
    },
  },
  mounted() {
    const isYouTube = window.innerWidth <= 768;

    if (isYouTube && this.currentMeditation.type === 'webinar') {
      this.isYouTube = true;
    } else {
      const playerId = 'player';
      this.player = new Plyr(`#${playerId}`, {
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: false,
          container: null,
        },
      });

      this.player.on('exitfullscreen', () => {
        document.querySelector('.plyr__video-embed.plyr__video-wrapper')
          .style.removeProperty('padding-bottom');
      });
    }

    this.noSleep = new NoSleep();
    this.noSleep.enable();

    if (this.currentMeditation.type === 'webinar') {
      this.isVisible = true;
    } else {
      setTimeout(() => {
        const element = document.querySelector('.plyr__progress__container');

        if (this.currentMeditation.isPayed || this.isPromoLanding) {
          element.setAttribute('data-text', this.mediationInfo.name);
        } else {
          element.setAttribute('data-text', `Фрагмент медитации "${this.mediationInfo.name}"`);
        }

        this.isVisible = true;
      }, 100);
    }
  },
  beforeDestroy() {
    this.noSleep.disable();
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/player";
 @import "../../assets/scss/common/forms";

.base-player {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('/images/groups-bg/player.jpg') $white 50% 50%;
    background-size: cover;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms;
    max-width: 767px;

    /* ipad */
    @media only screen and (min-width: 768px) {
        left: calc(50% - 384px);
    }

    &.opened {
        opacity: 1;
        visibility: visible;
    }

    .videoPLayer,
    .audioPlayer {
        position: relative;
        z-index: 5;
    }

    &__descr {
        position: fixed;
        bottom: 0;
        background: rgba(0,0,0,0.4);
        width: 100%;
        max-width: 767px;
        padding: 20px;
        box-sizing: border-box;
        max-height: calc(50vh - 150px);
        overflow: auto;
        color: $white;
        font-size: 14px;
        line-height: 21px;
        z-index: 3;
    }

    &__youTube {
        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &.promoPage {
            z-index: 3;
            position: relative;

            &:before {
                top: -80px;
            }

            iframe {
                margin-top: -80px;
            }
        }
    }

    &__cover {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        z-index: 2;
    }

    &__close-btn {
        width: 24px;
        height: 24px;
        background: $white;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 18px;
        z-index: 7;
        border: solid 1px $main-blue;

        &:before {
            width: 100%;
            height: 100%;
            display: flex;
            background:
                    url("/images/close-icon-blue.svg") no-repeat 50% 50%;
            content: '';
            background-size: 8px;
        }
    }

    &__add-to-cart-btn {
        background: $main-blue;
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 20px;
        margin-left: -80px;
        width: 165px;
        height: 46px;
        line-height: 18px;
        color: $white;
        font-size: 12px;
        padding: 4px 10px 4px 45px;
        box-sizing: border-box;
        z-index: 7;

        strong {
            font-weight: 600;
        }

        &:before {
            background: url("/images/cart-icon-white.svg") no-repeat 50% 50%;
            content: '';
            width: 45px;
            height: 46px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &.disabled {
            opacity: 0.8;
            cursor: not-allowed;
            background: $lightGray;
        }
    }
}

</style>
